.dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0rem;
}

.base-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 45vh;
  background: linear-gradient(45deg, #ff9999, #ff0000);
  background-size: 200% 200%;
  animation: gradientAnimation 6s infinite linear;
  color: white;
  text-align: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-bottom: 0.5rem;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.user-info {
  text-align: center;
}

.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.general-info-section, .experiences-section, .interests-hobbies-section, .projects-section {
  background: maroon;
  color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 0.5rem; 
  width: 80%;
  transition: transform 0.3s ease;
}

.general-info-section:hover,
.experiences-section:hover,
.interests-hobbies-section:hover,
.projects-section:hover {
  transform: scale(1.01);
}

.general-info-section h2, .experiences-section h2, .interests-hobbies-section h2, .projects-section h2 {
  margin-bottom: 10px;
}

.create-project-form {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 20px;
}

.project-title-input {
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #f5f5f5;
  width: 75%;
}

.create-project-button {
  background-color: white;
  color: maroon;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 10px;
  width: 25%;
}

.create-project-button:hover {
  background-color: #ddd;
}

.project-card {
  background-color: #fff;
  color: #000;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin: 0rem 1rem;
  text-decoration: none; /* Make sure the link does not have an underline */
  display: block; /* Ensure the whole card is clickable */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project-card:hover {
  transform: scale(1.02);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.project-card h3 {
  margin-top: 0;
}

.project-card p {
  margin: 0.5rem 0;
}
