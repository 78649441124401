@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

body {
  font-family: "Poppins", sans-serif;
}

.features-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 0;
  position: relative;
}

.features-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 153, 153, 0.8), rgba(255, 0, 0, 0.8)); /* Diagonal strip background */
  clip-path: polygon(0 0, 100% 30%, 100% 100%, 0 70%);
  z-index: -1;
}

.features-title {
  color: #000;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.features-boxes {
  display: flex;
  justify-content: center; /* Center the row */
  gap: 2rem;
  width: 80%; /* Adjust width to ensure it's centered */
  flex-wrap: nowrap; /* Prevent wrapping on larger screens */
}

.feature-box {
  background: maroon; /* Background color */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 20rem; /* Fixed width for each feature box */
  text-align: left;
  transition: transform 0.3s ease;
}

.feature-box:hover {
  transform: scale(1.02);
}

.feature-title {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #fff;
}

.feature-description {
  margin-bottom: 1rem;
  color: #ddd;
}

.feature-points {
  list-style-type: disc;
  padding-left: 1rem;
}

.feature-points li {
  margin-bottom: 0.5rem;
  color: #ddd;
}

/* Mobile Friendly Adjustments */
@media (max-width: 768px) {
  .features-boxes {
    flex-wrap: wrap; /* Allow wrapping for better layout on smaller screens */
    gap: 1rem; /* Reduce gap between boxes */
  }

  .feature-box {
    width: calc(100% - 2rem); /* Make feature boxes full width on mobile with some margin */
    margin-bottom: 1rem; /* Add margin bottom to separate boxes */
    padding: 1rem; /* Reduce padding for smaller screens */
  }

  .feature-title {
    font-size: 1.25rem; /* Reduce font size for smaller screens */
  }

  .feature-description {
    font-size: 1rem; /* Reduce font size for smaller screens */
  }

  .feature-points li {
    font-size: 0.9rem; /* Reduce font size for smaller screens */
  }
}

@media (max-width: 480px) {
  .features-title {
    font-size: 1.2rem; /* Further reduce font size for very small screens */
  }

  .feature-title {
    font-size: 1rem; /* Further reduce font size for very small screens */
  }

  .feature-description {
    font-size: 0.9rem; /* Further reduce font size for very small screens */
  }

  .feature-points li {
    font-size: 0.8rem; /* Further reduce font size for very small screens */
  }
}
