/* Global styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

/* Layout */
.project-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.project-content {
  display: flex;
  width: 100%;
  flex: 1;
}

/* Header */
.project-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40vh;
  background: linear-gradient(45deg, #ff9999, #ff0000);
  background-size: 200% 200%;
  animation: gradientAnimation 6s infinite linear;
  color: white;
  text-align: center;
  width: 100%;
  padding: 6rem 0;
}

@keyframes gradientAnimation {
  0%, 100% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
}

/* Tabs */
.project-tabs {
  width: 100%;
  background-color: #f5f5f5;
  padding: 10px 0;
}

.project-tabs ul {
  list-style-type: none;
  display: flex;
  justify-content: space-around;
  padding: 0;
  margin: 0;
}

.project-tabs li {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.project-tabs li.active { background-color: #ff9999; }
.project-tabs li.disabled { color: #ccc; cursor: not-allowed; }
.project-tabs li:not(.disabled):hover { background-color: #ffcccc; }

/* Content */
.project-details {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  overflow-y: auto;
}

.tab-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.content-box {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  padding: 2rem;
  margin-bottom: 0;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.content-box:hover {
  transform: scale(1.005);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

/* Forms and Inputs */
.input-row, .finalize-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 10px;
}

.problem-dropdown, .finalize-textbox, .experience-input, .experience-textarea {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.submit-button, .finalize-button, .generate-button {
  padding: 10px 20px;
  background-color: maroon;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button:hover, .finalize-button:hover, .generate-button:hover {
  background-color: brown;
}

.generate-ideas-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.generate-ideas-text {
  width: 70%;
}

.generate-button {
  width: 30%;
  max-width: 10rem;
  padding: 10px 20px;
  background-color: maroon;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.generate-button.disabled {
  background-color: gray;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .generate-ideas-container {
    flex-direction: column;
    align-items: stretch;
  }

  .generate-ideas-text {
    width: 100%;
    margin-bottom: 15px;
  }

  .generate-button {
    width: 100%;
    max-width: none;
    margin-left: 0;
  }
}

/* Ideas */
.ideas-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.idea-box {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.idea {
  font-size: 0.9rem;
  color: #333;
}

/* Misc */
.error-message {
  color: red;
  margin-top: 10px;
}

.video-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Media Queries */
@media (max-width: 768px) {
  .project-tabs ul {
    flex-direction: column;
    align-items: center;
    padding: 1rem 2rem;
  }

  .project-tabs li, .input-row, .problem-input, .submit-button {
    width: 100%;
  }

  .video { width: 280px; height: 160px; }
  .project-header { height: 20vh; }
  .input-row { flex-direction: column; }
}

@media (min-width: 769px) {
  .video-container {
    flex-direction: row;
    justify-content: space-around;
  }
}