@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

body {
  font-family: "Poppins", sans-serif;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem; /* Removed horizontal padding for better centering */
  position: fixed;
  width: 80%; /* 10% margin on either side */
  top: 4%; /* Positioned 4% down from the top */
  left: 10%; /* Centers the navbar with 10% margin on either side */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  border-radius: 1rem;
  z-index: 1000;
  transition: transform 0.3s ease;
  background-color: rgba(0, 0, 0, 0.8); /* Black background with 20% opacity */
}

.navbar.scrolled {
  background-color: rgba(0, 0, 0, 1); /* Black background with 50% opacity */
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.8);
  transform: scale(0.9);
}

.navbar-left {
  display: flex;
  align-items: center;
}

.logo {
  height: 3rem; /* Reduced height for a shorter navbar */
}

.navbar-right {
  display: flex;
  align-items: center;
}

.navbar-link {
  text-decoration: none;
  color: #fff;
  font-size: 14px; /* Slightly smaller font size */
  margin-left: 1.5rem; /* Reduced margin for better centering */
}

.navbar-link:first-child {
  margin-left: 0; /* Remove left margin for the first link */
}

.navbar-link:hover {
  color: #a9a9a9;
}

.signup-button, .login-button, .dashboard-button, .logout-button {
  background-color: maroon;
  color: white;
  border: none;
  padding: 8px 16px; /* Reduced padding for a smaller button */
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px; /* Slightly smaller font size */
  margin-left: 1.5rem; /* Reduced margin for better centering */
}

.signup-button:hover, .login-button:hover, .dashboard-button:hover, .logout-button:hover {
  background-color: brown;
}

.mobile-menu-icon {
  display: none;
  font-size: 24px;
  cursor: pointer;
  color: white;
}

@media (max-width: 768px) {
  .navbar {
    width: 100%;
    left: 0;
    top: 0;
    padding: 0;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.8); /* Full opacity */
    box-shadow: none;
    transform: none;
    border-radius: 0%;
  }

  .navbar.scrolled {
    background-color: rgba(0, 0, 0, 0.8); /* Full opacity */
    box-shadow: none;
    transform: none;
  }

  .navbar-left {
    margin: 1rem;
  }

  .navbar-right {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 1); /* Full opacity */
    text-align: center;
  }

  .navbar-right.open {
    display: flex;
  }

  .navbar-link {
    margin: 1rem 0;
  }

  .signup-button, .login-button, .dashboard-button, .logout-button {
    margin: 1rem 0;
  }

  .mobile-menu-icon {
    display: block;
    margin-right: 3rem;
  }
}
