.join-container {
  display: flex;
  height: 100vh;
  width: 100%;
}

.join-left {
  background-color: maroon;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 2rem;
  color: white;
}

.join-quote {
  font-size: 1rem;
  font-style: italic;
}

.join-right {
  background-color: white;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: start; /* Start at the top */
  align-items: center;
  padding: 2rem;
  overflow-y: auto;
  margin-top: 7rem; /* Add margin from the top */
}

.join-form,
.info-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  max-width: 400px;
}

.join-form h1,
.info-form h2 {
  color: #333;
  margin-bottom: 1rem;
}

.join-form label,
.info-form label {
  margin: 10px 0;
  width: 100%;
}

.join-form input,
.info-form input,
.info-form textarea {
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  background-color: #f5f5f5; /* Filled input style */
}

.join-form button,
.info-form button {
  padding: 10px 20px;
  background-color: maroon;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.join-form button:hover,
.info-form button:hover {
  background-color: brown;
}

@media (max-width: 768px) {
  .join-left {
    display: none;
  }
}

.label-row {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Changed from space-around to space-between */
  width: 100%;
  margin: 10px 0;
}

.label-row span {
  flex: 0 0 80%; /* Takes up 80% of the row */
  text-align: left;
}

.label-row input[type="checkbox"] {
  flex: 0 0 20%; /* Takes up 20% of the row */
  width: auto; /* Override the 100% width */
  margin: 0;
}

.submit-row {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  margin-right: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
