@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

body {
  font-family: "Poppins", sans-serif;
}

.testimonials-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
}

.testimonials-title {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #333;
}

.testimonials-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  width: 80%;
}

.testimonial-box {
  background: maroon;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  padding: 1rem;
  width: 20rem;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease;
  color: white;
}

.testimonial-box:hover {
  transform: scale(1.03);
}

.testimonial-row {
  display: flex;
  align-items: center;
  margin-bottom: 0rem;
}

.testimonial-image {
  height: 6rem;
  width: 6rem;
  margin-right: 1rem;
  border: 1px;
  border-color: #fff;
}

.testimonial-content {
  flex: 1;
}

.testimonial-name {
  font-size: 1.2rem;
  color: #fff;
  margin-bottom: 0.5rem;
}

.testimonial-title {
  font-size: 1rem;
  color: #ddd;
  margin-bottom: 0.5rem;
}

.testimonial-text {
  font-size: 0.9rem;
  color: #ddd;
}

.testimonials-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  width: 80%;
}

.footer-button {
  background-color: maroon;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  margin-left: 1rem;
}

.footer-button:hover {
  background-color: brown;
}

.footer-text {
  font-weight: bold;
  font-size: 1.2rem;
}
