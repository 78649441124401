.carousel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0;
    overflow: hidden; /* Ensure the container hides overflow */
  }
  
  .carousel-container h2 {
    margin-bottom: 1rem;
    font-family: "Poppins", sans-serif;
    color: #333;
  }
  
  .carousel {
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  
  .carousel-track {
    display: flex;
    width: calc(300%); /* Adjusted width to accommodate all duplicated items */
    animation: scroll 60s linear infinite;
    margin-top: 1rem;
    will-change: transform;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-33.33%); /* Adjusted to loop through the first set of items */
    }
  }
  
  .carousel-item {
    flex: 0 0 8rem; /* Set the size of each logo */
    margin: 0 2rem;
    transition: transform 0.3s ease;
  }
  
  .carousel-item:hover {
    transform: scale(1.05);
  }
  
  .carousel-logo {
    height: 8rem;
    width: auto;
  }

  @media (max-width: 768px) {
    .carousel-track {
      width: calc(2000%); /* Adjusted width to accommodate all duplicated items */
      animation: scroll 36s linear infinite;
    }
  }
  