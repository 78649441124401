@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

body {
  font-family: "Poppins", sans-serif;
}

.homebase-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh; /* Reduced height to 70% of viewport height */
  background: linear-gradient(45deg, #ff9999, #ff0000); /* Old gradient */
  background-size: 200% 200%;
  animation: gradientAnimation 6s infinite linear;
  color: white;
  text-align: center;
  position: relative;
  overflow: hidden;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
    background-color: #ff9999;
  }
  50% {
    background-position: 100% 50%;
    background-color: #ff0000;
  }
  100% {
    background-position: 0% 50%;
    background-color: #ff9999;
  }
}

.homebase-topline {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  margin-top: 2rem;
  font-family: 'Franklin Gothic Light', 'Arial Narrow', Arial, sans-serif;
  color: #eeeeee; /* Different font color */
}

.homebase-title {
  font-size: 4rem; /* Increased font size for the main title */
  margin-bottom: 0rem;
  margin-top: 5rem;
  max-width: 60%; /* Set a maximum width for the title */
  font-family: 'Montserrat', sans-serif; /* Changed font */
  line-height: 1.2; /* Reduced space between lines */
  color: #ffffff; /* Different font color */
}

.homebase-subtitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-family: 'Roboto', sans-serif; /* Changed font */
  color: #dddddd; /* Different font color */
}

.homebase-buttons {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.homebase-button {
  padding: 0.75rem 1.5rem;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.homebase-button:hover {
  background-color: #555;
}

/* Mobile Friendly Adjustments */
@media (max-width: 768px) {
  .homebase-container {
    height: 60vh; /* Adjust height to auto for better fitting */
    padding: 2rem 1rem; /* Add padding for better spacing */
  }

  .homebase-title {
    font-size: 2.5rem; /* Reduce font size for smaller screens */
    margin-top: 2rem;
    max-width: 90%; /* Increase max-width for better fit */
  }

  .homebase-subtitle {
    font-size: 1.2rem; /* Reduce font size for smaller screens */
    margin-bottom: 1.5rem;
  }

  .homebase-buttons {
    flex-direction: column; /* Stack buttons vertically on mobile */
    gap: 0.5rem;
  }

  .homebase-button {
    width: 100%; /* Make buttons full width on mobile */
    padding: 0.75rem;
  }
}

@media (max-width: 480px) {
  .homebase-title {
    font-size: 2rem; /* Further reduce font size for very small screens */
  }

  .homebase-subtitle {
    font-size: 1rem; /* Further reduce font size for very small screens */
  }

  .homebase-topline {
    font-size: 0.9rem;
  }
}
